<template>
    <div class="authorizeQRCodes">
        <div>请通过企业微信查看</div>
<!--        <a class="weixin-link" href="https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=wxc8c8428d0d0fb353&agentid=1000058&redirect_uri=http%3A%2F%2Freporttest.xlmediawatch.com%2F%23%2FLoginCodes&state=hellowecom">-->
<!--            <img-->
<!--                    src="//rescdn.qqmail.com/node/wwopen/wwopenmng/style/images/independent/brand/300x40_white$5ecdf536.png"-->
<!--                    srcset="//rescdn.qqmail.com/node/wwopen/wwopenmng/style/images/independent/brand/300x40_white_2x$ce44f9f2.png 2x"-->
<!--                    alt="企业微信登录"-->
<!--            >-->
<!--        </a>        -->
<!--        <img src="../assets/img/198_bdcde6b17dd298261df0044a23531030.png" alt="">-->
        <div id="qrCode" ref="qrCodeDiv"></div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2';
    // import html2canvas from 'html2canvas'
    export default {
        name: "authorizeQRCodes",
        data(){
           return{
               agentid:'',
               appid:'',
               url:''
           }
        },
        created(){

        },
        methods:{
            getapp(){
                // new QRCode(document.getElementById('qrcode'), 'your content');
                var qrcode = new QRCode('qrcode', {
                    text: JSON.parse(sessionStorage.getItem("url")),
                    width: 200,
                    height: 200,
                    colorDark : '#000000',
                    colorLight : '#ffffff',
                    correctLevel : QRCode.CorrectLevel.H
                });
            }
        },
        mounted() {
            let url = JSON.parse(sessionStorage.getItem("url"))
            new QRCode(this.$refs.qrCodeDiv, {
                text: url,
                width: 200,
                height: 200,
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
            });
        }
        // mounted() {
        //
        //     this.$nextTick(() => {
        //         this.getapp()
        //     })
        //     // alert(this.appid)
        //     // alert(this.agentid)
        //     // alert(this.url)
        //     // window.WwLogin({
        //     //     "id" : "wx_reg",
        //     //     "appid": this.appid,
        //     //     "agentid": this.agentid,
        //     //     "redirect_uri": this.url,
        //     //     "state" : "",
        //     //     "href" : "",
        //     // });
        //     // var wwLogin = new WwLogin({
        //     //     "id": "wx_reg",
        //     //     "appid": this.appid,
        //     //     "agentid": this.agentid,
        //     //     "redirect_uri": this.url,
        //     //     "state": "",
        //     //     "href": "",
        //     //     "lang": "zh",
        //     // });
        // }
    }


</script>

<style scoped>
    .authorizeQRCodes{
        padding-top: 10vh;
        /*height: 100vh;*/
        width: 100%;
        font-size: 30px;
        font-weight: 700;
        text-align: center;

    }
    .authorizeQRCodes>div{
        width: 100%;
        display: flex;
        justify-content: center;

    }
    #qrCode{
        margin-top: 4vh;
    }
    /*.impowerBox .qrcode {width: 200px;}*/
    /*.impowerBox .title {display: none;}*/
    /*.impowerBox .info {width: 200px;}*/
    /*.status_icon {display: none  !important}*/
    /*.impowerBox .status {text-align: center;}*/
</style>